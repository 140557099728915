import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons from react-icons
import logo from '../images/StoryBuild_Badge.png';
import '../styles.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <a href="https://story.build"><img src={logo} alt="Logo" /></a>
        </div>
        <div className="tagline-item">Products for the digitally distracted →</div>
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      <nav className={isOpen ? 'nav-open' : ''}>
        <div className={`nav-items ${isOpen ? 'show-menu' : ''}`}>
          <div className="nav-item"><a href="https://books.story.build" target="_blank" rel="noreferrer">Read Books</a></div>
          <div className="nav-item"><a href="/shop/#All">Buy Journals</a></div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
